import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { useEffect } from 'react';
import { COUNTRY_MAP_LOCALE } from '../localization/constants';
import { environment } from '../utils/env.utils';

// Used to cache bust the favicon and og image in case it changes.
// Source: https://stackoverflow.com/questions/2208933/how-do-i-force-a-favicon-refresh/7116701#7116701
const FAVICON_VERSION = 2;
const OG_IMAGE_VERSION = 2;

const Html = () => {
  const { t } = useTranslation('Metadata');
  const location = useLocation();

  const title = t('title');
  const description = t('description');
  const ogImageUrl = `${window.location.origin}/images/og-image.png?v=${OG_IMAGE_VERSION}`;

  useEffect(() => {
    document.documentElement.lang = COUNTRY_MAP_LOCALE[environment.country];
  }, []);

  return (
    <>
      <meta name="ui-version" content={environment.version} />

      {/* General */}
      <title>{title}</title>
      <meta name="description" content={description} />

      {/* Favicon */}
      <link rel="manifest" href="/manifest.webmanifest" />
      <link
        rel="icon"
        href={
          import.meta.env.DEV ? `/favicon-dev.ico?v=${FAVICON_VERSION}` : `/favicon.ico?v=${FAVICON_VERSION}`
        }
        sizes="any"
      />
      <link
        rel="icon"
        href={
          import.meta.env.DEV
            ? `/favicon-svg-dev.svg?v=${FAVICON_VERSION}`
            : `/favicon-svg.svg?v=${FAVICON_VERSION}`
        }
        type="image/svg+xml"
      />
      <link rel="apple-touch-icon" href={`/apple-touch-icon.png?v=${FAVICON_VERSION}`} />

      {/* Open Graph / Facebook */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={`${window.location.origin}${location.pathname}`} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={ogImageUrl} />
      <meta property="og:image:width" content="699" />
      <meta property="og:image:height" content="324" />

      {/* Twitter */}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={ogImageUrl} />
      <meta name="twitter:site" content="@fairlosweden" />

      {environment.env !== 'production' ? <meta name="robots" content="noindex,nofollow" /> : null}
    </>
  );
};

export default Html;
