import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { trackPageView } from '../../../../analytics';
import { useModalStore } from '../../../../store/modalStore';

function useHandleLocationChange() {
  const location = useLocation();
  const { closeNonPersistedModal } = useModalStore();

  useEffect(() => {
    void trackPageView();
    closeNonPersistedModal();
  }, [closeNonPersistedModal, location.pathname]);
}

export default useHandleLocationChange;
