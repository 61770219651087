import { Button } from '@fairlo/ui-kit';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { routeMap } from '../../../../routes/routeMap';
import Modal from '../../Modal';
import { ModalParagraph } from '../shared';

const PaymentProtectionInsuranceTerms = () => {
  const { t } = useTranslation(['Modal', 'Button']);
  const navigate = useNavigate();

  return (
    <Modal title={t('PaymentProtectionInsuranceSuccess.title')}>
      <ModalParagraph>{t('PaymentProtectionInsuranceSuccess.info')}</ModalParagraph>
      <Modal.Actions>
        <Button onClick={() => navigate(routeMap.home)}>{t('Button:clear')}</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default PaymentProtectionInsuranceTerms;
