import { useEffect } from 'react';
import { useLocation } from 'react-router';

// A simple hook that resets the window scroll whenever the URL path changes.
const useScrollTop = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
};

export default useScrollTop;
