import { useTranslation } from 'react-i18next';

import { AnchorButton } from '@fairlo/ui-kit';
import { Link } from 'react-router';
import { DepositType } from '../../../../api';
import { QueryParam } from '../../../../constants';
import { routeMap } from '../../../../routes/routeMap';
import { constructURL } from '../../../../utils/url';
import Modal from '../../Modal';
import { ModalParagraph } from '../shared';
import { ModalProps } from '../types';

type Payload = {
  depositAmount: string;
  depositType: DepositType;
};
type Props = ModalProps<Payload>;

const DepositFail = ({ closeModal, payload }: Props) => {
  const { t } = useTranslation('Modal');
  const { depositAmount, depositType } = payload;

  return (
    <Modal title={t('DepositFail.title')}>
      <ModalParagraph>{t('DepositFail.info')}</ModalParagraph>
      <Modal.Actions>
        <AnchorButton
          as={Link}
          onClick={closeModal}
          to={constructURL(routeMap.credit.deposit.UK.summary, {
            relative: true,
            queryParams: {
              [QueryParam.DEPOSIT_AMOUNT]: depositAmount,
              [QueryParam.IS_MANUAL_DEPOSIT]: 'true'
            }
          })}
          variant="outlined"
          color="neutral"
        >
          {t('DepositFail.payManually')}
        </AnchorButton>

        <AnchorButton
          as={Link}
          onClick={closeModal}
          to={constructURL(routeMap.credit.deposit.UK.summary, {
            relative: true,
            queryParams: {
              [QueryParam.DEPOSIT_AMOUNT]: depositAmount,
              [QueryParam.DEPOSIT_TYPE]: depositType
            }
          })}
        >
          {t('DepositFail.tryAgain')}
        </AnchorButton>
      </Modal.Actions>
    </Modal>
  );
};

export default DepositFail;
