import { AnchorButton, Text } from '@fairlo/ui-kit';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router';
import useBankAccountQuery from '../../../../hooks/useBankAccountQuery';
import { routeMap } from '../../../../routes/routeMap';
import Modal from '../../Modal';
import { ModalProps } from '../types';

type Payload = {
  transactionId: string;
};

const WithdrawalSuccess = ({ payload, closeModal }: ModalProps<Payload>) => {
  const { t } = useTranslation('Modal');
  const { data: bankAccount } = useBankAccountQuery();

  return (
    <Modal title={t('WithdrawalSuccess.title')}>
      <Text>
        {bankAccount?.directBank ? t('WithdrawalSuccess.directPayoutInfo') : t('WithdrawalSuccess.info')}
      </Text>
      <Modal.Actions>
        <AnchorButton
          variant="outlined"
          color="neutral"
          as={Link}
          to={routeMap.getFairnessReceiptById(payload.transactionId)}
          onClick={closeModal}
        >
          {t('WithdrawalSuccess.fairnessReceiptButton')}
        </AnchorButton>
        <AnchorButton as={Link} to={routeMap.credit.monthlyPayment} onClick={closeModal}>
          {t('WithdrawalSuccess.button')}
        </AnchorButton>
      </Modal.Actions>
    </Modal>
  );
};

export default WithdrawalSuccess;
