import { useTranslation } from 'react-i18next';

import { AnchorButton, Text } from '@fairlo/ui-kit';
import { Link } from 'react-router';
import { QueryParam } from '../../../constants';
import { routeMap } from '../../../routes/routeMap';
import { constructURL } from '../../../utils/url';
import Modal from '../Modal';
import { ModalProps } from './types';

type Payload = { remainingDebt?: number };
type Props = ModalProps<Payload>;

const AgreementHasDebt = ({ payload }: Props) => {
  const { t } = useTranslation('Modal');

  return (
    <Modal title={t('AgreementHasDebt.title')}>
      <Text>{t('AgreementHasDebt.text', { amount: payload?.remainingDebt })}</Text>
      <Modal.Actions>
        <AnchorButton
          as={Link}
          to={constructURL(routeMap.credit.deposit.root, {
            relative: true,
            queryParams: {
              [QueryParam.SELECT_MAX_AMOUNT]: 'true'
            }
          })}
        >
          {t('AgreementHasDebt.button')}
        </AnchorButton>
      </Modal.Actions>
    </Modal>
  );
};

export default AgreementHasDebt;
