import { Button } from '@fairlo/ui-kit';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router';
import { ProductName, QueryParam } from '../../../constants';
import { routeMap } from '../../../routes/routeMap';
import Modal from '../Modal';
import { ModalParagraph } from './shared';
import { ModalProps } from './types';

type Payload = {
  depositAmount: string;
  productName: ProductName;
};
type Props = ModalProps<Payload>;

const redirectBaseMap: Record<ProductName, string> = {
  credit: routeMap.credit.deposit.root,
  debtConsolidation: routeMap.debtConsolidation.deposit
};

const DepositFail = ({ closeModal, payload }: Props) => {
  const { t } = useTranslation(['Modal', 'Button']);
  const navigate = useNavigate();
  const redirectBase = redirectBaseMap[payload.productName];
  const { depositAmount } = payload;

  return (
    <Modal title={t('DepositFail.title')}>
      <ModalParagraph>{t('DepositFail.info', { depositAmount })}</ModalParagraph>
      <Modal.Actions>
        <Button
          onClick={() => {
            void navigate(`${redirectBase}?${QueryParam.DEPOSIT_AMOUNT}=${depositAmount}`);
            closeModal();
          }}
        >
          {t('Button:clear')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default DepositFail;
