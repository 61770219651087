import { Button, Text } from '@fairlo/ui-kit';
import { useTranslation } from 'react-i18next';
import Modal from '../../Modal';
import { ModalProps } from '../types';

const DirectPaymentOrderNotFound = ({ closeModal }: ModalProps) => {
  const { t } = useTranslation('Modal');

  return (
    <Modal title={t('DirectPaymentOrderNotFound.title')}>
      <Text>{t('DirectPaymentOrderNotFound.body')}</Text>
      <Modal.Actions>
        <Button onClick={closeModal}>{t('Button:close')}</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default DirectPaymentOrderNotFound;
