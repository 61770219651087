import { ReactNode, createContext, useContext, useState } from 'react';

import PaymentProvider from './index';

export type State = {
  openPaymentProvider: (url: string) => void;
};

export const Context = createContext<State | undefined>(undefined);
Context.displayName = 'PaymentProviderContext';

type Props = {
  children: ReactNode;
};

const PaymentProviderProvider = ({ children }: Props) => {
  const [url, setUrl] = useState<string>();

  return (
    <Context value={{ openPaymentProvider: setUrl }}>
      {url ? <PaymentProvider url={url} /> : null}
      {children}
    </Context>
  );
};

export const useOpenPaymentProvider = () => {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error(`${useOpenPaymentProvider.name} must be used within a ${PaymentProviderProvider.name}`);
  }

  return context.openPaymentProvider;
};

export default PaymentProviderProvider;
