import { Colors } from '@fairlo/ui-kit';
import { ComponentProps, useId } from 'react';
import styled from 'styled-components';

type Props = ComponentProps<'textarea'> & {
  error?: boolean;
  errorMessage?: string;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  caret-color: ${Colors.GREEN_700};
  border-radius: 4px;
  border: 1px solid ${Colors.GRAY_300};
  font-size: var(--font-size-text-m);
  line-height: var(--line-height-text-m);
  padding: 8px 12px;
  font-weight: inherit;

  transition: all 250ms cubic-bezier(0, 0, 0.2, 1);

  &:hover {
    border-color: ${Colors.GRAY_900};
  }

  &:focus {
    outline: none;
    border-color: ${Colors.GREEN_700};
  }

  &:disabled {
    color: ${Colors.GRAY_200};
    border-color: ${Colors.GRAY_300};
  }

  &[aria-invalid='true'] {
    caret-color: ${Colors.RED_700};
    border-color: ${Colors.RED_700};
  }
`;

const ErrorMessage = styled.span`
  color: ${Colors.RED_700};
  font-size: 10px;
  margin-top: 4px;
  margin-left: 16px;
  font-size: var(--font-size-text-xs);
  line-height: var(--line-height-text-xs);

  // The element needs to be visible at all time due to aria-live="polite"
  // but it should not take up space when there is no error message or
  // the textarea is not invalid
  height: 0;
  textarea[aria-invalid='true'] + &:not(:empty) {
    height: auto;
  }
`;

const Textarea = ({ errorMessage, error = !!errorMessage, ...restProps }: Props) => {
  const errorMessageId = useId();

  return (
    <Wrapper>
      <StyledTextarea
        {...(error ? { 'aria-invalid': true } : {})}
        {...(errorMessage ? { 'aria-errormessage': errorMessageId } : {})}
        {...restProps}
      />
      <ErrorMessage id={errorMessageId} aria-live="polite">
        {!!errorMessage && errorMessage}
      </ErrorMessage>
    </Wrapper>
  );
};

export default Textarea;
