import { JSX } from 'react/jsx-runtime';
import { useModalStore } from '../../store/modalStore';
import Modal from './Modal';
import { ModalProps } from './components/types';
import modalMap, { CommonModalTypes, SEModalTypes, UKModalTypes } from './modalMap';

const getModalComponent = (type: string) => {
  const splitType = type.split('.') as [CommonModalTypes] | ['SE', SEModalTypes] | ['UK', UKModalTypes];
  let ModalComponent: (props: ModalProps<any>) => JSX.Element | null = () => <></>;

  if (splitType.length === 1) {
    ModalComponent = modalMap[splitType[0]];
  } else if (splitType.length === 2) {
    if (splitType[0] === 'SE') {
      ModalComponent = modalMap.SE[splitType[1]];
    } else if (splitType[0] === 'UK') {
      ModalComponent = modalMap.UK[splitType[1]];
    }
  }

  return ModalComponent;
};

const ModalRenderer = () => {
  const { showModal, type, payload, closeAction, confirmAction, closeModal } = useModalStore();

  const ModalComponent = type ? getModalComponent(type) : () => <></>;

  const handleClose = closeAction ?? closeModal;

  return (
    <Modal.Root isOpen={showModal} onDismiss={handleClose}>
      <ModalComponent payload={payload} confirmAction={confirmAction} closeModal={handleClose} />
    </Modal.Root>
  );
};

export default ModalRenderer;
