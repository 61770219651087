import BankAccountUpdateInProgress from './BankAccountUpdateInProgress';
import ConfirmTerminatePaymentProtectionInsurance from './ConfirmTerminatePaymentProtectionInsurance';
import CreditTierMigrationSuccess from './CreditTierMigrationSuccess';
import DirectPaymentOrderNotFound from './DirectPaymentOrderNotFound';
import MigrationToNewTierNeeded from './MigrationToNewTierNeeded';
import OverduePayment from './OverduePayment';
import PaymentFreeMonthWithdrawalBlocked from './PaymentFreeMonthWithdrawalBlocked';
import PaymentProtectionInsuranceSuccess from './PaymentProtectionInsuranceSuccess';
import PaymentProtectionInsuranceTerminated from './PaymentProtectionInsuranceTerminated';
import PaymentProtectionInsuranceTerms from './PaymentProtectionInsuranceTerms';
import PaymentProtectionInsuranceToBeTerminated from './PaymentProtectionInsuranceToBeTerminated';
import WithdrawalSuccess from './WithdrawalSuccess';

const SE = {
  BANK_ACCOUNT_UPDATE_IN_PROGRESS: BankAccountUpdateInProgress,
  CONFIRM_TERMINATE_PAYMENT_PROTECTION_INSURANCE: ConfirmTerminatePaymentProtectionInsurance,
  CREDIT_TIER_MIGRATION_SUCCESS: CreditTierMigrationSuccess,
  DIRECT_PAYMENT_ORDER_NOT_FOUND: DirectPaymentOrderNotFound,
  MIGRATION_TO_NEW_TIER_NEEDED: MigrationToNewTierNeeded,
  OVERDUE_PAYMENT: OverduePayment,
  PAYMENT_FREE_MONTH_WITHDRAWAL_BLOCKED: PaymentFreeMonthWithdrawalBlocked,
  PAYMENT_PROTECTION_INSURANCE_SUCCESS: PaymentProtectionInsuranceSuccess,
  PAYMENT_PROTECTION_INSURANCE_TERMINATED: PaymentProtectionInsuranceTerminated,
  PAYMENT_PROTECTION_INSURANCE_TERMS: PaymentProtectionInsuranceTerms,
  PAYMENT_PROTECTION_INSURANCE_TO_BE_TERMINATED: PaymentProtectionInsuranceToBeTerminated,
  WITHDRAWAL_SUCCESS: WithdrawalSuccess
} as const;

export default SE;
