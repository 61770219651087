const swedish = {
  Common: {
    money: '{{amount, currency}}',
    moneyWithTrailingZeros: '{{amount, currency(displayTrailingZeros: true)}}',
    moneyWithoutSign: '{{amount, currency(displaySign: false)}}',
    percentage: '{{percentage}} %',
    currency: 'kr',
    termsAndConditions: 'Allmänna villkor',
    termsAndConditionsFairloEverydayLoans: 'Allmänna Villkor Fairlo Varda',
    new: 'NYHET',
    requiredField: 'Vänligen fyll',
    back: 'Tillbaka',
    cancel: 'Avbryt',
    before: 'Före',
    after: 'Efter',
    comingSoon: 'Kommer snart',
    close: 'Stänga',
    from: 'Från',
    to: 'Till',
    interest: 'Ränta:',
    fees: 'Avgifter',
    totalLoan: 'Låneutrymme',
    products: {
      credit: 'Varda',
      debtConsolidation: 'Lösa',
      paymentProtectionInsurance: 'Betalskydd'
    },
    contactUs: 'Kontakta kundtjänst',
    number6: 'sex',
    number12: 'tolv',
    loading: 'Läser in',
    count_ordinal_one: '{{count}}:a',
    count_ordinal_two: '{{count}}:a',
    count_ordinal_few: '{{count}}:e',
    count_ordinal_other: '{{count}}:e'
  },
  Metadata: {
    title: 'Fairlo, Vardagskredit på dina villkor, livet ska vara fair.',
    description:
      'Låneutrymmen med tydlig effektiv ränta, helt utan avgifter. Ansök när du vill: svaret syns direkt på skärmen!'
  },
  Header: {
    apply: 'Ansök',
    openMenu: 'Öppna meny',
    closeMenu: 'Stäng meny',
    aboutUs: 'Om Fairlo',
    expandYourLoanSpace: 'Levla ditt låneutrymme',
    customerService: 'Kundservice',
    commonQuestions: 'Vanliga frågor',
    login: 'Logga in',
    close: 'Stänga',
    home: 'Mina Sidor',
    logout: 'Logga ut',
    paymentPlan: 'Betalplan & historik',
    bankAccount: 'Anslutet bankkonto',
    userProfile: 'Min profil',
    cookieSettings: 'Inställningar för cookie'
  },
  CookieBanner: {
    shortText: 'Vi använder oss av cookies.\nLäs mer i vår <anchor>cookie policy</anchor>',
    longText:
      'Vi använder oss av cookies för att förbättra vår hemsidas upplevelse. Du kan läsa mer om cookies i vår <anchor>cookie policy</anchor>'
  },
  ApiErrors: {
    bankId:
      'Whoops, BankID signeringen avbröts, försök igen. Om problemet kvarstår vänligen kontakta kundservice@fairlo.se',
    loginTookLong:
      'Whoops, inloggningen dröjde för länge, försök igen. Om problemet kvarstår vänligen kontakta kundservice@fairlo.se',
    somethingWentWrong:
      'Whoops något gick fel, prova gärna igen. Om problemet kvarstår vänligen kontakta kundservice@fairlo.se',
    inactivity:
      'Whoops, du blev utloggad på grund av inaktivitet, logga in igen för att fortsätta. Om problemet kvarstår vänligen kontakta kundservice@fairlo.se'
  },
  fieldErrors: {
    invalidEmail: 'Vänligen fyll i en giltig e-postadress',
    invalidMobile: 'Vänligen fyll i ditt mobilnummer'
  },
  SignFailed: {
    title: 'Hoppsan!',
    text1: 'Din BankID-signering godkändes inte. Försök gärna igen.',
    text2: 'Kontakta oss på kundservice@fairlo.se om felet kvarstår.',
    trySigningAgain: 'Prova signera igen'
  },
  Loader: {
    defaultMessage:
      'Häng kvar, vi säkerställer att allt är perfekt och redo för dig. Det kan ta ett par minuter (men antagligen betydligt kortare) så gå ingenstans.',
    applicationPending:
      'Tryck inte på tillbaka knappen\n\nHäng kvar, vi säkerställer att allt är perfekt och redo för dig. Det kan ta ett par minuter (men antagligen betydligt kortare) så gå ingenstans.'
  },
  Navigation: {
    questions: 'Frågor',
    aboutUs: 'Om oss',
    terms: 'Villkor',
    getTheEveryDayCredit: 'Ansök',
    complaint: 'Klagomål',
    logout: 'Logga ut',
    logIn: 'Logga in',
    home: 'Hem',
    bankAccount: 'Anslutet bankkonto',
    userProfile: 'Min Profil',
    paymentPlanAndHistory: 'Betalplan & historik',
    deposit: 'Återbetala extra',
    withdrawal: 'Gör uttag',
    monthlyPayment: 'Ändra månadsbelopp',
    new: 'NYHET',
    menu: 'Meny'
  },
  CreditGranted: {
    heading: 'Grattis!\nDu blev beviljad\n{{creditLimit, currency}}',
    grantedMessage:
      'I nästa steg ansluter du enkelt ditt bankkonto för att kunna börja använda tjänsten. Smidigt va?',
    cancelMessage:
      'Du kan avbryta Fairlo genom att kostnadsfritt betala av krediten på Mina sidor när som helst. Helt utan bindningstid.',
    button: 'Anslut nu och gör ett uttag'
  },
  Button: {
    logInToMyPages: 'Logga in på mina sidor',
    clear: 'Klart',
    done: '$t(Button:clear)',
    save: 'Spara',
    endEverydayCredit: 'Avsluta Fairlo',
    ok: 'OK',
    tryAgain: 'Försök igen',
    close: 'Stäng'
  },
  Login: {
    title: 'Logga in på mina sidor.',
    personNumber: 'Personnummer',
    personNumberInvalidFormat: 'Format: YYYYMMDDXXXX',
    action: 'Logga in',
    loanUser: {
      title: 'Hej där,',
      text: 'Lånetjänsten är nedlagt sedan 1 maj 2024. <a>Kontakta kundservice</a> för mer information.'
    }
  },
  CreditAgreementDisclaimer: {
    disclaimer:
      'Vid signering godkänner du: \n<terms>Allmänna Villkoren</terms>, <sekki>SEKKI</sekki>, <creditAgreement>Kreditavtalet</creditAgreement>, samt <directDebit>Autogiromedgivande</directDebit>',
    disclaimerWithPaybackPlan:
      'Vid signering godkänner du: \n<terms>Allmänna Villkoren</terms>, <sekki>SEKKI</sekki>, <creditAgreement>Kreditavtalet</creditAgreement>, <directDebit>Autogiromedgivande</directDebit>, samt <paybackPlan>Initial betalningsplan</paybackPlan>'
  },
  LoanCheckerFailed: {
    title: 'Hoppsan!',
    text: 'Prova göra om ansökan. Om problemet kvarstår vänligen kontakta kundservice@fairlo.se.'
  },
  SelectAccountUnfinished: {
    title: 'Inget konto valt!',
    info: 'Det gick inte att ansluta bankkonto och autogiro men försök gärna igen. Behöver du hjälp kan du kontakta oss via '
  },
  SelectAccount: {
    SelectAccountSuccess: {
      heading: 'Du är klar <private>{{userName}}</private>.',
      text1: 'Välkommen till din nya liv-händer kreditbuffert.',
      text2: 'Sammanfattning av din liv-händer buffert',
      creditLimit: 'Låneutrymme',
      interestRate: 'Ränta',
      text3: 'Du betalar bara ränta på det belopp du använder för den tid du använder det.',
      text4:
        'Det tillkommer en månadsavgift på {{installmentFee, currency}}, en etableringsavgift på {{setupFee, currency}} och vi bjuder på första uttaget (ord. pris {{withdrawalFee, currency}}).',
      text5:
        'Du kan antingen ta ut det maximala tillgängliga beloppet eller så kan du välja ett specifikt belopp, när det har bekräftats kommer pengarna att överföras direkt.',
      button1: 'Välj belopp för uttag',
      button2: 'Välj maxuttag'
    },
    SelectAccountError: {
      heading: 'Välj din bank och sedan ditt konto',
      button: 'Försök igen'
    }
  },
  PaymentProvider: {
    willRedirect: 'Snart kommer du att få välja din bank.'
  },
  Footer: {
    expandCredit: 'Utöka ditt låneutrymme',
    commonQuestions: 'Vanliga frågor',
    contactUs: 'Kundservice',
    address: 'Adress',
    careers: 'Karriär'
  },
  BisnodeErrorCodes: {
    DR101: 'Adress saknas.',
    DR102: 'Utflyttad.',
    DR103: 'Förvaltare eller förmyndare förordnad.',
    DR104: 'Skyddad identitet.',
    DR105: 'Boxadress.',
    DR106: 'Giltig adress saknas.',
    DR113: 'Förlorad ID-handling.',
    DR209: 'Tidigare eller pågående utmätning.',
    DR210: 'Tidigare eller pågående skuldsanering.',
    DR492: 'Tidigare eller pågående skuldsaldo.',
    DR493: 'Tidigare eller pågående skuldsaldo.',
    DR497: 'Tidigare eller pågående skuldsaldo.',
    DR500: 'Yngre än 20 år.',
    DR501: 'Inflyttad för mindre än 6 månader sedan.',
    DR502: 'Anmärkningar.',
    DR506: 'Taxerad inkomst under 120 000 kr'
  },
  Deposit: {
    description:
      'Ange den summa som du vill återbetala idag, du måste fortfarande betala ditt månadsbelopp även om du gör en extra inbetalning.',
    sliderTitle: 'Dra för att välja belopp:',
    desktopInfoBox:
      'Amortera {{totalAmount, currency}}. Nyttjat belopp kommer att minska till {{amountLeft, currency}} och nya månadsbeloppet blir {{monthlyPayment, currency}} per månad i {{period}} månader.',
    desktopInfoBoxMaxPrincipal:
      'Amortera {{totalAmount, currency}} varav upplupen ränta t.o.m. idag {{interest, currency}}. Nyttjat belopp kommer att minska till {{amountLeft, currency}} och nya månadsbeloppet blir {{monthlyPayment, currency}} per månad i {{period}} månader.',
    depositButton: 'Amortera {{amount, currency}}',
    maxAmountText:
      'Grattis! Detta betalar av hela din skuld inklusive ränta, totalt {{amount, currency}}, till och med idag. Du behöver inte göra några fler inbetalningar.'
  },
  MonthlyPayment: {
    description: 'Välj det belopp som du vill återbetala varje månad.',
    desktopSliderTitle: 'Dra för att välja vilket månadsbelopp du vill ha:',
    desktopButton: 'Ändra till {{amount, currency}}/månad',
    changeMonthlyPaymentButton: 'Ändra till {{amount, currency}}/månad'
  },
  BankAccount: {
    title: 'Anslutet bankkonto',
    selectAccount: 'Anslut konto',
    bank: 'Bank',
    accountNumber: 'Kontonummer',
    autogiro: 'Autogiro',
    notConnected: 'Ej anslutet',
    updateInProgress: 'Uppdatering pågår',
    connected: 'Anslutet'
  },
  PaymentPlanTable: {
    date: 'Datum',
    transaction: 'Händelse',
    amount: 'Belopp',
    eventsUpcoming: 'KOMMANDE HÄNDELSER',
    status: 'Status',
    creditTitle: 'Varda',
    debtConsolidationTitle: 'Lösa',
    paymentProtectionInsuranceTitle: 'Betalskydd',
    amountToPay: 'Att betala',
    totalAmount: 'Totalt att betala',
    noTransactionsMsg: 'Det finns ingen historik',
    noPaybackPlansMsg: 'Du har ingen nyttjad kredit',
    paybackPlanWillBeUpdated:
      'Du har just nu en betalfri månad. I samband med din nästa betalning kommer din betalplan att uppdateras',
    InvoiceStatus: {
      PAID: 'Betald',
      OVERDUE: 'Förfallen',
      CHARGE: 'Pågående',
      NOT_PAID: ''
    },
    PaybackItemType: {
      PAYBACK_PRINCIPAL: 'Kapital',
      PAYBACK_INTEREST: 'Ränta',
      EXTENDED_DUE_DATE_INTEREST: 'Ränta på faktura med anstånd',
      OVERDUE_FEE: 'Påminnelse',
      DEBT_COLLECTION_FEE: 'Inkasso',
      DOUBLE_OVERDUE_FEE: 'Krav',
      DEBT_COLLECTION_INTEREST: 'Ränta på inkassofaktura',
      REMIT: 'Efterskänkes',
      ACCRUED_INTEREST: 'Ackumulerad ränta',
      REMAINING_AMOUNT_WRITTEN_OFF: 'Avskrivet restbelopp',
      UNKNOWN: 'Okänd',
      TERMINATION: 'Uppsägning',
      PAYMENT_PROTECTION_INSURANCE: 'Premium',
      WITHDRAWAL_FEE: 'Uttagsavgift',
      DIRECT_DEBIT_MISSING_FEE: 'Saknat Autogiro'
    },
    TransactionItem: {
      PAY_EXTRA: 'Insättning',
      COMPLETE_LOAN: 'Insättning',
      PAYBACK: 'Månadsbelopp',
      BULK_PAYBACKS: 'Månadsbelopp',
      PAYOUT: 'Uttag',
      ADMIN_PAY_EXTRA: 'Insättning',
      SOLD_BAD_DEBT: 'Såld icke presterande',
      OVERPAYMENT: 'Insättning',
      REMAINING_AMOUNT_WRITTEN_OFF: 'Avskrivet restbelopp',
      DISBURSEMENT: 'Uttag',
      NORDISKA_TRANSFER: 'Nordiska överföring',
      CREDIT_LOSS: 'Kreditförlust',
      PAYMENT_FREE_MONTH: 'Betalfri månad'
    }
  },
  UserProfile: {
    title: 'Min Profil',
    gotoPage: 'Ändra',
    terminatePaymentInsuranceButton: 'Avsluta Betalskydd',
    bankAccount: 'Anslutet bankkonto',
    AccountDetails: {
      title: 'Mina uppgifter',
      email: 'E-post',
      mobile: 'Mobilnummer',
      errorToastDuplicateContact:
        'Hoppsan! Verkar som att mailadressen eller telefonnumret du uppgav existerar redan i vårt system. Kontakta kundtjänst för ytterligare hjälp.',
      ocrPrompt: 'För att betala manuellt med OCR använder du nedan uppgifter:',
      ocr: 'OCR',
      ocrBankTransfer: 'Bankgiro',
      ocrDisclaimer: 'Ordinarie månadsbelopp betalas enbart genom autogiro.'
    },
    FinancialDetails: {
      title: 'Min ekonomi',
      monthlyLabel: 'Total månadsinkomst efter skatt',
      houseCostLabel: 'Din boendekostnad per månad',
      transportationCostLabel: 'Din transportkostnad per månad',
      transportationCostInfo: 'Din kostnad för att utnyttja kollektivtrafik samt diverse fordon',
      otherCostLabel: 'Din månadskostnad för ytterligare lån',
      otherLoanCostInfo: 'Din kostnad för övriga lån samt borgensåtaganden.',
      numOfChildrenLabel: 'Antal barn under 18 år i hushållet:',
      adultsLiveWithLabel: 'Antal vuxna över 18 år i hushållet:',
      adultsLiveWithOptionAlone: 'Ensam',
      adultsLiveWithOptionSeveral: 'Flera',
      numberOfChildrenOption0: '0',
      numberOfChildrenOption1: '1',
      numberOfChildrenOption2: '2',
      numberOfChildrenOption3: '3+',
      monthlyIncomePlaceholder: 'Måste vara större än noll kr',
      otherLoanCostPlaceholder: 'Vänligen fyll i dina lånekostnader',
      monthlyHouseCostPlaceholder: 'Vänligen fyll i din boendekostnad'
    }
  },
  ErrorBoundary: {
    somethingWentWrong: 'Woops..något fick fel.'
  },
  CompleteLoanFail: {
    title: 'Din kredit är fortfarande aktiv!',
    info: 'Vi kunde inte dra återstående skuld från angivet konto. Kontrollera konto, täckning och prova sedan igen. Behöver du hjälp kan du alltid kontakta oss via'
  },
  MyCredit: {
    overview: 'Översikt',
    whatDoYouWantToDo: 'Vad vill du göra?'
  },
  Withdrawal: {
    description: 'Välj den summa som du vill föra över till ditt kopplade bankkonto.',
    mobileButton: 'Överför {{amount, currency}}',
    desktopButton: 'Ja tack, Överför {{amount, currency}}',
    desktopSliderTitle: 'Dra för att välja belopp att ta ut:',
    infoBox:
      'Överför {{amount, currency}} till ditt konto. Nyttjat belopp kommer öka till {{increaseAmount, currency}} och nya månadsbeloppet blir {{monthlyPayment, currency}} per månad i {{returnPeriod}} månader.'
  },
  TierMigration: {
    title: 'Ditt uppdaterade avtal',
    text1:
      'När du har signerat kan du göra uttag igen. Den enda skillnaden är en ny årsränta om <b>{{percentage}} %</b>.',
    text2: 'Du behåller som vanligt:',
    li1: 'Fria insättningar',
    li2: 'Anpassa månadsbelopp kostnadsfritt',
    li3: 'Betala enbart ränta för det du nyttjar',
    li4: 'Kreditbelopp {{amount, currency}}',
    button: 'Signera nytt avtal'
  },
  NoActiveCreditPage: {
    title: 'Hej där,',
    info: 'Du har just nu ingen tillgänglig kredit hos Fairlo. Det kan bero på att du ännu inte beviljats, avslutat din tjänst eller att den förfallit på grund av obetalda fakturor.',
    button: 'Ansök'
  },
  Modal: {
    OverduePayment: {
      title: 'Betala förfallet belopp',
      info: 'Det totala beloppet att betala in är {{amount, currency}}. Detta inkluderar förfallet månadsbelopp samt eventuella förseningskostnader fram till idag.',
      infoItemTitle: 'Förfallet belopp',
      button: 'Betala {{amount, currency}}'
    },
    OverduePaymentSuccess: {
      title: 'Tack för din betalning',
      info: 'Betalat belopp',
      disclaimer:
        'Observera att om du har en pågående autogirodragning ingår den ej i detta belopp och kommer att dras närmsta dagarna.'
    },
    LevlaOfferSuccess: {
      title: 'Sådärja, då var det klart.',
      info: 'Du kan nu använda din utökade livet-händer-buffert',
      infoItemTitle1: 'Låneutrymme',
      infoItemTitle2: 'Ränta'
    },
    WithdrawalBlocked: {
      title: '<private>{{username}}</private>, du kan tyvärr inte göra uttag just nu',
      info: 'Vi på Fairlo tar sund långivning på allvar och vår bedömning är att det inte vore ansvarsfullt att tillåta fler uttag. Vi kan inte i dagsläget veta när du igen kommer att kunna göra uttag men lovar att meddela dig så fort läget ändras. Tills dess är det superviktigt att du fullföljer din betalningsplan. Dina vänner på Fairlo'
    },
    WithdrawalInProgress: {
      title: 'Pengarna är på väg, men det dröjer lite länger än vanligt',
      info: 'Ibland kan transaktioner ta lite längre tid än vanligt. Du bör ha pengarna på ditt konto inom en kvart. Tveka inte att höra av dig till kundservice om du har några frågor.'
    },
    WithdrawalAmountInvalid: {
      title: 'Felaktigt belopp',
      info: 'Beloppet du valt att ta ut är inte valbart längre. Vänligen välj ett annat belopp.',
      button: 'Klart'
    },
    ServiceTerms: {
      title: 'Tjänstevillkor',
      subTitle: 'Inhämtning av konto- och kreditinformation',
      term1:
        '1. Dessa villkor för inhämtning av konto- och kreditinformation (”Tjänstevillkoren”) tillämpas när Fairlo AB, organisationsnummer 559084-1101, inhämtar din konto- och kreditinformation hos Tredje Part (”Tjänsten”). Med Tredje Part avses bank, finansinstitut eller annan juridisk person där du har en eller flera krediter.',
      term2:
        '2.  Med ditt samtycke inhämtar Fairlo din konto- och kreditinformation hos Tredje Part. Den information som inhämtas är dina inloggningsuppgifter, kredituppgifter, fakturauppgifter, inbetalningsuppgifter och köphistorik. Med ledning av informationen kan Fairlo bedöma hur mycket du betalar för dina krediter. Med utgångspunkt i den informationen kan Fairlo bedöma hur mycket det skulle kosta för dig att låta Fairlo lösa dina krediter hos Tredje Part och i stället få en samlad kredit hos Fairlo. Den konto- och kreditinformation vi inhämtar sker därför i jämförelsesyfte.',
      term3:
        '3.  Du förbinder dig inte till något genom att använda Tjänsten. De uppgifter som inhämtas ligger endast till grund för det erbjudande Fairlo kommer att kunna ge dig.',
      term4: '4. Du måste vara minst 18 år samt godkänna Tjänstevillkoren för att kunna använda Tjänsten.',
      term5:
        '5. När du använder Tjänsten godkänner och uppdrar du åt Fairlo att för din räkning använda dina inloggningsuppgifter för inhämtning av din konto- och kreditinformation hos Tredje Part. Den information som inhämtas ligger till grund för det erbjudande som Fairlo kommer att kunna ge dig. Utifrån erbjudandet bestämmer du själv om du vill låta Fairlo lösa dina krediter hos Tredje Part och i stället få en samlad kredit hos Fairlo.',
      term6:
        '6. Fairlos erbjudande förutsätter att den konto- och kreditinformation som inhämtas från Tredje Part är korrekt och att du i övrigt ger en rättvisande och korrekt bild av din ekonomiska situation. Fairlos erbjudande förutsätter också att du är behörig att använda Tjänsten samt tillhandahålla oss konto- och kreditinformationen.',
      term7:
        '7. Fairlo strävar efter att den information som inhämtas är korrekt. Fairlo garanterar däremot inte tillförlitligheten eller korrektheten i den information som inhämtas.',
      term8:
        '8. Du kan inte rikta några anspråk mot Fairlo av skäl som har sin grund i tekniska brister i Tjänsten, funktionalitet eller den konto- och kreditinformation som inhämtas. Fairlo ansvarar inte heller för beslut som du tar och som har sin grund i din användning av Tjänsten.',
      term9:
        '9. Du ansvarar för att ditt tillhandahållande av konto- och kreditinformation inte utgör intrång i annans rätt eller överträdelse av lag eller annat tillämpligt regelverk. Du ansvarar också för att i förekommande fall inhämta berörd rättsinnehavares godkännande att använda Tjänsten.',
      term10:
        '10. Fairlo förbehåller sig rätten att begränsa din användning av Tjänsten om du överträder Tjänstevillkoren eller när din användning av Tjänsten i annat fall kan medföra skada för Fairlo eller annan part.',
      term11:
        '11. Den konto- och kreditinformation som inhämtas kan utgöra personuppgifter. Fairlo behandlar dina personuppgifter i enlighet med Fairlos personuppgiftspolicy. Du hittar den på www.fairlo.se.',
      term12:
        '12. Om du väljer att ingå ett kreditavtal med Fairlo kommer Fairlos Allmänna villkor att gälla. Innan du ingår ett kreditavtal med Fairlo kommer du att se vilka villkor och övriga förutsättningar som gäller för krediten. En förutsättning för att kunna ingå ett avtal om kredit med Fairlo är att du godkänner de Allmänna villkoren samt det kreditavtal och den betalningsplan som Fairlo tillhandahåller.'
    },
    AgreementHasDebt: {
      title: 'Just nu kan du inte avsluta',
      text: 'Du har en pågående inbetalning som håller på att bearbetas av oss. Under tiden går det inte att avsluta tjänsten. Prova gärna igen om ett par bankdagar, när dragningen är avslutad.',
      button: 'Klart'
    },
    RepayOutstandingBalance: {
      title: 'Du kan inte avsluta just nu!',
      info: 'För att avsluta behöver du först reglera kvarvarande skuld. Det gör du enkelt via Mina Sidor.',
      button: 'Klart'
    },
    LastPaybackStillProcessing: {
      title: 'Du har en kvarvarande skuld',
      info: 'Du kan tyvärr inte avsluta ditt konto just nu eftersom vi för närvarande behandlar din månadsbetalning. Försök gärna igen efter att din betalning har genomförts. Det kan ta upp till en bankdag från att betalning dragits från ditt konto till att den är färdigbehandlad hos oss.',
      button: 'Klart'
    },
    OverdueChargeInProgress: {
      title: 'Just nu kan du inte göra uttag',
      info: 'Du har en försenad betalning som kommer att dras via autogiro kommande dagar. Till dess att det försenade beloppet är betalt kan du inte göra uttag.'
    },
    DirectDebitChargeInProgress: {
      title: 'Du har en pågående autogiro-dragning',
      info: 'För tillfället går det inte att göra uttag under pågående autogiro-dragning. Just nu görs ett dragningsförsök på {{chargeInProgressAmount, currency}}. Så fort din betalning gått igenom kommer du kunna göra uttag som vanligt igen. Tack för ditt tålamod.'
    },
    DirectDebitMandateNotFound: {
      title: 'Just nu kan du inte göra uttag',
      info: 'Ditt autogiro har tagits bort. För att ansöka om att uppgradera din tjänst behöver du aktivera den igen. Välj Anslutet Bankonto i menyn och klicka sedan på Anslut konto för att lägga till autogiro, därefter kan du göra uttag igen.'
    },
    DebtConsolidationDirectDebitMandateNotFound: {
      title: 'Just nu kan du inte Lösa fler krediter',
      info: 'Vi saknar ett autogiromedgivande. Vänligen koppla på autogiro igen för att kunna använda tjänsten. Du gör det enkelt genom att klicka på Anslutet Bankonto i menyn och klicka sedan på Anslut konto och följ intruktionerna.'
    },
    InsufficientFunds: {
      title: 'Hoppsan, det går inte att ta ut beloppet just nu.',
      info: 'Din senaste betalning har inte hunnit behandlas ännu. Försök igen nästkommande bankdag.'
    },
    DepositSuccess: {
      title: 'Sådär ja, då var allt klart',
      info: 'Du har gjort en amortering. Tack för din betalning.',
      depositAmount: 'Amorterat belopp',
      monthlyAmount: 'Nytt månadsbelopp',
      button: '$t(Button:clear)'
    },
    DepositFail: {
      title: 'Överföringen misslyckades',
      info: 'Transaktionen stoppades av någon anledning. Vänligen försök igen. Om problemet kvarstår kan du höra av dig till vår kundervice.'
    },
    ChangeMonthlyPaymentSuccess: {
      title: 'Sådärja, då har du nytt månadsbelopp',
      info: 'Här ser du ditt nya månadsbelopp.',
      listItemTitle: 'Månadsbelopp'
    },
    ChangeMonthlyPaymentFailed: {
      title: 'Något blev fel',
      info: 'Det gick inte att ändra din månadsbetalning. Testa igen är du snäll.'
    },
    BankAccountUpdateInProgress: {
      title: 'Hoppsan, kontot håller på att anslutas',
      info: 'Det tar vanligtvis två vardagar innan autogirot är anslutet. Därefter kan du återigen ändra ditt bankkonto.'
    },
    BankAccountUpdateSuccess: {
      title: 'Sådär ja, då var ditt bankkonto anslutet',
      info: 'Du kan redan nu börja använda tjänsten men autogiro tar oftast några vardagar, ibland längre, innan det är anslutet.',
      button: 'Klart'
    },
    BankAccountUpdateFail: {
      title: 'Det gick inte att ansluta konto!',
      info: 'Det gick inte att ansluta bankkonto och autogiro men försök gärna igen. Behöver du hjälp kan du kontakta oss på kundtjänst.'
    },
    LoanAgreement: {
      title: 'Kreditavtal Fairlo Varda'
    },
    ConfirmWithdrawal: {
      title: 'Vill du göra ett uttag på {{withdrawalAmount, currency}}?',
      info: 'Nedan kan du se ditt nya nyttjade belopp och uppdaterade månadsbelopp.',
      infoWithFees:
        'Nedan ser du ditt uppdaterade månadsbelopp och totalt nyttjad kredit inkl Uppläggningsavgift {{setupFee, currency}} och Uttagsavgift {{withdrawalFee, currency}}.',
      infoTitle1: 'Månadsbelopp i {{numberOfInstallments}} månader',
      infoTitle2: 'Nyttjat belopp',
      button: 'Överför {{withdrawalAmount, currency}}'
    },
    NoConnectedBankAccountInfo: {
      title: 'Inget bankkonto anslutet',
      info: 'Du har inte anslutit ett bankkonto ännu. Klicka här eller gå in på Anslutet Bankkonto för att välja vilket konto transaktionerna skall göras till.'
    },
    NoRemainingDebtInfo: {
      title: 'Säker på att du vill säga upp din kredit?',
      info: 'Du vet väl att Fairlo inte kostar något när den inte används? Om du avslutar din kredit så förlorar du tillgången till ditt konto och måste ansöka på nytt.',
      feedbackInfo: 'Berätta gärna varför du lämnar oss nedan.',
      feedbackLabel: 'Återkoppling',
      action: 'Avsluta Fairlo'
    },
    PaybackPlanModal: {
      title: 'Din initiala betalningsplan',
      messageBelowTitle1: 'Förmedlad av Fairlo AB (Fairlo) ',
      messageBelowTitle2:
        'Efter att du blivit beviljad krediten kan du när som helst gå in på Mina Sidor och ändra planen efter eget behag helt utan kostnad. Du kan göra det hur många gånger du vill och det börjar gälla direkt. Precis som vi lovat dig.',
      dueDate: 'Förfallodatum',
      interest: 'Ränta',
      amortization: 'Amortering',
      installmentFee: 'Månadsavgift',
      totalToPay: 'Totalt att betala',
      messageBelowTable:
        'Effektiv årsränta blir {{effectiveInterest}} och den nominella {{interest}}. Totalt att betala tillbaka blir {{totalAmount, currency}} varav {{amount, currency}} är amorterat kapital och {{totalCost, currency}} ränta.',
      total: 'Totalt'
    },
    RemainingDebtInfo: {
      title: 'Du har en kvarvarande skuld.',
      creditProduct: 'Fairlo Varda',
      debtConsolidationProduct: 'Fairlo Lösa',
      info: 'Tråkigt att du lämnar oss men vi hoppas ha kunnat vara ett stöd i din ekonomi och livsstil. Behöver du oss i framtiden finns vi här för dig, på dina villkor!',
      infoSecond:
        'För att avsluta behöver du först reglera kvarvarande skuld. Det gör du enkelt via Mina Sidor. Klicka nedan för att komma dit.',
      action: 'Mina Sidor'
    },
    WithdrawalFail: {
      title: 'Överföringen misslyckades',
      info: 'Transaktionen stoppades av någon anledning. Vänligen försök igen. Om problemet kvarstår kan du höra av dig till vår kundervice.'
    },
    WithdrawalSuccess: {
      title: 'Pengarna är på väg',
      directPayoutInfo:
        'Dina pengar bör finnas på ditt konto inom några minuter. Glöm inte att du kan ändra din månatliga återbetalning när som helst för att minska din kreditkostnad.',
      info: 'Dina pengar bör finnas på ditt konto senast nästkommande bankdag. Glöm inte att du kan ändra din månatliga återbetalning när som helst för att minska din kreditkostnad.',
      fairnessReceiptButton: 'Visa Fairnesskvitto',
      button: 'Ändra återbetalningsplan'
    },
    DirectPaymentFailed: {
      title: 'Något gick fel…',
      info: 'Testa att fullfölja betalningen igen.'
    },
    DirectPaymentPaid: {
      title: 'Du har redan betalat',
      info: 'Tack för din betalning'
    },
    DirectPaymentDetails: {
      info: 'Din fakturaspec',
      discountInfo: 'Din fakturaspec. Grön text visar besparingar gjorda efter strukna kostnader.',
      discount: 'Förseningskostnader'
    },
    DirectPaymentSuccess: {
      title: 'Swishat!',
      info: 'Tack för din betalning.'
    },
    DirectPaymentNothingToPay: {
      title: 'Det finns inget att betala',
      info: 'Vi hittar inga obetalda fakturor. Logga in på Mina sidor för att se din betalplan.'
    },
    DirectPaymentGenericError: {
      title: 'Något gick fel...',
      info: 'Logga in på mina sidor.'
    },
    WithdrawalNotPossible: {
      title: 'Hoppsan!',
      info: 'För att göra uttag krävs att du har minst {{minimumWithdrawalAmount, currency}} i outnyttjad kredit.',
      button: 'Klart'
    },
    PaymentProtectionInsuranceSuccess: {
      title: 'Toppen, du är nu försäkrad.',
      info: 'Nu kan du känna dig trygg tack vare Fairlo Betalskydd. Du har fått ett välkomstbrev med all information till din epostadress.'
    },
    MigrationToNewTierNeeded: {
      title: 'Uppdatera avtal',
      info: 'För att fortsätta göra uttag behöver du uppdatera ditt kreditavtal. Det tar bara någon minut.',
      readMore: 'Läs mer'
    },
    CreditTierMigrationSuccess: {
      title: 'Sådär ja!',
      info: 'Ditt kreditavtal är uppdaterat och du kan nu göra uttag igen.'
    },
    PaymentFreeMonthWithdrawalBlocked: {
      title: 'Just nu kan du inte göra uttag.',
      info: 'Detta beror på att du har utnyttjat en betalfri månad. Uttag kan tidigast göras 6 månader efter en betalfri månad.'
    },
    ConfirmTerminatePaymentProtectionInsurance: {
      title: 'Är du säker på att du vill ta bort skyddet?',
      info: 'Om du klickar “Ja, jag är säker” så avslutar vi Fairlo Betalskydd, vilket tar bort skyddet vid till exempel arbetslöshet eller sjukskrivning. Är du säker på att du vill det?',
      button: 'Ja, jag är säker'
    },
    PaymentProtectionInsuranceToBeTerminated: {
      title: 'Så, då var det klart!',
      info: 'Betalskyddet är nu avslutat. Försäkringen gäller fram till månadens slut.',
      button: 'Klart'
    },
    PaymentProtectionInsuranceTerminated: {
      title: 'Så, då var det klart!',
      info: 'Betalskyddet är nu avslutat.',
      button: 'Klart'
    },
    TermsAndConditionsUpdated: {
      title: 'Vi har uppdaterat våra Allmänna Villkor',
      info: 'Du behöver inte göra något, men om du vill kan du klicka på knappen nedan för att läsa den senaste versionen.',
      button1: 'Läs mer',
      button2: 'Klart'
    },
    DirectPaymentOrderNotFound: {
      title: 'Något gick fel...',
      body: 'Vi kunde inte hämta dina obetalda fakturor just nu. Försök igen senare eller kontakta vår kundtjänst för hjälp.',
      button: 'Klart'
    }
  },
  Credit: {
    title: 'Fairlo Varda',
    cards: {
      summaryTitle: 'Låna pengar när du behöver',
      noBankAccount: {
        title: 'Bankkonto saknas',
        body: 'Klicka här för att ansluta ett bankkonto.'
      },
      noDirectDebit: {
        title: 'Autogiro saknas',
        body: 'Klicka här för att ansluta ett bankkonto och undvik en onödig avgift på 249 kr på din nästa faktura.'
      }
    },
    rejections: {
      defaultTitleWithName:
        '<private>{{username}}</private>, vi kan denna gång inte bevilja dig Fairlo Varda.',
      defaultTitle: 'Den här gången kan vi inte bevilja dig någon kredit',
      defaultText:
        'Ansvarsfullt långivande är viktigt för oss. Vi är därför alltid noggranna med att alltid försöka vara så säkra vi kan på att en kredit är rätt för dig. Och den här gången bedömde vi att just vår produkt inte är rätt för just dig just nu. Men saker förändras så prova gärna igen i framtiden!',
      bankIdIssueText:
        'vi kan just nu inte genomföra en ansökan med det aktuella bankid:et då det är mindre än tio dagar sedan det utfärdades. Detta är en säkerhetsåtgärd från vår sida. Vänligen försök igen senare.',
      numberOfLoansExceededText:
        'Vi är smickrade över att du gillar vår tjänst så mycket! Men vi kan tyvärr inte erbjuda dig en kredit den här gången. Du har tagit 12 lån inom en 12-månadersperiod och därför uppnått maxgränsen för antalet lån. Har du frågor eller funderingar kring detta är du mer än välkommen att höra av dig till vår kundservice.',
      existingCreditTitle: '<private>{{username}}</private>, du har redan en aktiv kredit hos oss.',
      existingCreditText1:
        'Klicka nedan och logga in på mina sidor​ för att se och hantera din kredit. Du kan ändra plan, amortera extra eller avsluta när du vill med ett klick utan kostnad.',
      existingCreditText2:
        'Tveka inte att höra av dig till vår kundservice om du har frågor eller funderingar.',
      overduePaymentsText:
        'Du har obetalda amorteringar från en tidigare kredit. Du behöver betala dessa innan du kan få ut en ny kredit. Hör av dig till vår kundservice om du vill ha hjälp att ordna detta.',
      terminatedLoanText:
        'Du har sedan tidigare ett förfallet lån hos oss och vi kan därför inte erbjuda dig Fairlo Varda. Vänligen hör av dig till vår kundservice på kundservice@fairlo.se för mer information.',
      userBlockedText:
        'Du är för närvarande spärrad i vårt system. Vänligen hör av dig till vår kundservice för mer information.',
      recentBisnodeCallTitle:
        '<private>{{username}}</private>, den här gången kan vi inte bevilja dig en kredit.',
      recentBisnodeCallText:
        'Du har nyligen ansökt om en kredit hos oss och inte blivit beviljad. För att inte påverka din kreditvärdighet negativt med många ansökningar tillåter vi enbart en ansökan var 90:e dag. Prova att söka igen den {{dateToReapply}}. Du kan alltid höra av dig till kundservice om du har frågor.',
      ucScoreMissingTitle: 'Just nu kan vi inte godkänna din ansökan.',
      ucScoreMissingText:
        'Då UC inte har skickat oss hela din fil kan vi inte behandla din ansökan. Om du har några frågor kring detta, var snäll och kontakta UC.',
      ucServiceErrorTitle: 'Just nu kan vi inte godkänna din ansökan.',
      ucServiceErrorText:
        'På grund av ett tekniskt fel hos UC kunde vi inte inhämta den information vi behöver för att behandla din ansökan. Testa att ansöka igen senare idag. Vid en ny ansökan kan ytterligare kreditupplysningar komma att tas.',
      kalpTitle: 'Den här gången kan vi inte bevilja dig någon kredit',
      kalpText1:
        'Enligt våra beräkningar går kalkylen för “Kvar-att-leva-på” inte ihop. Vi kan därför inte erbjuda dig en kredit för tillfället. Vi har använt oss av de uppgifter du lämnat gällande inkomster och utgifter, samt information från externa källor för att komma fram till vårt beslut.',
      kalpText2:
        'Har du frågor eller funderingar är du mer än välkommen att höra av dig till vår kundservice! Du är så klart även välkommen att försöka igen längre fram.',
      bisnodeTitle: 'Den här gången kan vi inte bevilja dig någon kredit',
      bisnodeText1:
        'Ansvarsfullt långivande är viktigt för oss. Vi är därför alltid noggranna med att alltid försöka vara så säkra vi kan på att en kredit är rätt för dig. Och den här gången bedömde vi att just vår produkt inte är rätt för just dig just nu. Nedan finner du mer info om anledningen bakom vårt beslut:',
      bisnodeText2:
        'Om du har några frågor är du så klart varmt välkommen att kontakta oss via vår kundtjänst.',
      bisnodeText3:
        'Vi hoppas också att du vill komma tillbaka lite längre fram och prova igen. Vi vet att läget förändras!'
    }
  },
  Levla: {
    rejections: {
      defaultTitle: 'Just nu kan du inte Levla',
      defaultText1:
        'Tyvärr uppfyllde inte din ansökan våra krav för att vi ska kunna låta dig att levla upp din kredit. För att kunna levla behöver du ha skött dina lån och krediter samt att ha en god kreditvärdighet enligt kreditupplysning.',
      defaultText2:
        'Du kan självklart fortfarande använda din Varda som tidigare. Logga in på Mina sidor för att göra uttag, insättningar eller ändra din betalplan. Tveka inte att höra av dig till <customerServiceMail /> om du har några frågor eller funderingar.',
      defaultButton: 'Logga in på mina sidor',
      overduePaymentsTitle: 'Vi kan inte utöka ditt låneutrymme just nu.',
      overduePaymentsText1:
        'Du har ett låneutrymme hos oss som tyvärr har minst en förfallen amortering. För att vi ska kunna utöka ditt låneutrymme behöver du först betala det förfallna beloppet. Klicka på knappen nedan eller logga in på mina sidor för att betala.',
      overduePaymentsText2:
        'Tveka inte att höra av dig till <customerServiceMail /> om du har några frågor eller funderingar.',
      overduePaymentsButton: 'Betala det förfallna beloppet',
      maxProductOrEligibilityCheckerRejectedTitle: 'Just nu kan du inte Levla',
      maxProductOrEligibilityCheckerRejectedText1:
        'Vi kan för tillfället inte erbjuda dig högre kreditgräns än den du redan har. Det kan bero på lite olika anledningar, till exempel att du kanske redan har vårt mest fördelaktiga produkt.',
      maxProductOrEligibilityCheckerRejectedText2:
        'Detta påverkar inte din nuvarande livet-händer-buffert. Tveka inte att höra av dig till <customerServiceMail>kundservice@fairlo.se</customerServiceMail> om du har några frågor eller funderingar.',
      directDebitMandateNotFoundTitle: 'Du behöver aktivera autogiro för att levla.',
      directDebitMandateNotFoundText:
        'Ditt autogiro har tagits bort. För att ansöka om att uppgradera din tjänst behöver du aktivera ditt autogiro igen. Klicka nedan för att gå in på <bankAccountLink>app.fairlo.se/bank-account</bankAccountLink> och välj sedan “Anslut konto” för att lägga till autogiro, därefter kan du ansöka om att levla igen. Lycka till!',
      directDebitMandateNotFoundButton: 'Anslutet bankkonto',
      tooManyInquiriesTitle: 'Just nu kan du inte Levla',
      tooManyInquiriesText1:
        'Just nu har du lite för många färska Bisnode-förfrågningar, så du behöver avvakta med din ansöka. Prova gärna igen lite längre fram.',
      tooManyInquiriesText2:
        '<levlaApplyLink>Här</levlaApplyLink> kan du läsa mer om vad som krävs för att levla.',
      tooManyInquiriesText3:
        'Tveka inte på att höra av dig till <customerServiceMail /> om du har några funderingar.',
      tooManyInquiriesButton: 'Logga in på mina sidor',
      recentBisnodeCallTitle: '<private>{{username}}</private>, vi kan inte öka ditt låneutrymme just nu.',
      recentBisnodeCallText1:
        'Du har nyligen ansökt om utökat kreditutrymme och inte blivit beviljad. För att inte påverka din kreditvärdighet negativt med många ansökningar tillåter vi enbart en ansökan var 90:e dag. Prova att ansöka igen den {{dateToReapply}}.',
      recentBisnodeCallText2:
        'Har du frågor eller funderingar kring detta är du mer än välkommen att höra av dig till vår kundservice.',
      ongoingChargeTitle: 'Just nu kan du inte Levla',
      ongoingChargeText1:
        'Vi kan tyvärr inte behandla din ansökan just nu eftersom den skickades in under betalningsändringsperioden. Under denna tid kan vi inte garantera att betalningar går igenom som förväntat. Försök gärna igen när perioden är över.',
      recentPaymentFreeMonthTitle: 'Just nu kan du inte Levla.',
      recentPaymentFreeMonthText1:
        'Detta beror på att du har utnyttjat en betalfri månad. Du kan tidigast Levla 6 månader (180 dagar) efter en betalfri månad.',
      recentPaymentFreeMonthText2:
        'Tveka inte att höra av dig till <customerServiceMail>kundservice@fairlo.se</customerServiceMail> om du har några frågor eller funderingar.'
    },
    offer: {
      title: 'Du är beviljad en utökad <span>kredit med {{creditLimitDifference, currency}}</span>',
      before: 'Innan',
      after: 'Efter',
      creditAmountUsed: 'Använt saldo',
      interestRate: 'Nominell ränta',
      maxInstallments: 'Maxlängd',
      installmentAmount: 'Månadsbelopp',
      totalCost: 'Total kostnad',
      installmentsPerMonth: '{{numberOfInstallments}} mån',
      text1:
        'Vi erbjuder dig ett utökat kreditutrymme med ytterligare {{creditLimitDifference, currency}} vilket ökar ditt total utrymme till <span>{{offeredCreditLimit, currency}}</span> med en sänkt ränta på <span>{{offeredInterestRate}}</span>.',
      text2: 'Jämför före och efter nedan',
      bottomText1:
        'En uppläggningsavgift på {{setupFee, currency}} läggs till på din totala kredit och en månadsavgift om {{installmentFee, currency}} kommer att tas ut. Dessa avgifter är medräknade i “efter uppgradering” ovan.',
      bottomText2: 'Efter uppgradering till nya villkoren kan man inte gå tillbaka till föregående villkor.',
      button: 'Signera nytt avtal'
    },
    levlaCard: {
      title: 'Ansök om utökad kreditgräns och lägre ränta',
      body: 'Vi bedömer att du har god chans att bli beviljad förbättrade villkor. Klicka här för att läsa mer och ansöka.'
    }
  },
  ErrorInfo: {
    title: 'Oj, något gick fel.',
    text: 'Gå tillbaka, försök igen och om problemet kvarstår kontakta kundtjänst.',
    button: 'Tillbaka till hemsidan'
  },
  LevlaApply: {
    title: 'Ansök om att utöka <b>krediten och sänka räntan</b>',
    text: 'Ansök för att få bättre villkor på din kredit hos Fairlo. Du har möjlighet att få lägre ränta, en lägre totalkostnad plus att du betalar av krediten snabbare.\n\nOm du blir beviljad får du:',
    uspReducedInterestRate: 'Räntan sänkt till {{interestRate}}',
    uspFreeFirstWithdrawal: 'Första uttaget gratis (ord. {{withdrawalFee, currency}})',
    uspFasterRepayment: 'Betala av krediten snabbare',
    uspIncreasedCreditLimit: 'Ökad kredit med {{creditLimit, currency}}',
    disclaimer: 'Vi gör enbart en kreditupplysning från <b>Dun & Bradstreet</b> (tidigare <b>Bisnode</b>)',
    button: 'Ansök nu'
  },
  Products: {
    label: 'Fairlo Produkter',
    menu: {
      myCredit: 'Varda',
      debtConsolidation: {
        label: 'Lösa',
        tag: 'Nyhet'
      }
    },
    cards: {
      usageSummary: {
        total: 'Låneutrymme:',
        used: 'Nyttjat:',
        monthlyAmount: 'Månadsbelopp:',
        monthlyAmountDetails: '{{amount, currency}} i {{period}} mån',
        interest: 'Ränta:',
        nextChargeLabel: 'Nästa dragning:',
        nextChargeDate: '{{chargeDate, datetime(month: short; day: numeric)}}',
        nextChargeOngoing: 'pågående'
      },
      credit: {
        title: 'Fairlo Varda'
      },
      debtConsolidation: {
        title: 'Fairlo Lösa',
        bodyInactive: 'Lös dyra krediter och flytta dem till Fairlo med ett klick.'
      },
      overdue: {
        title: 'Du har ett förfallet belopp',
        body: 'Betala {{amount, currency}} för att undvika fler kostnader och återaktivera tjänsten.'
      },
      paymentFreeMonth: {
        title: 'Betalfri månad',
        body: 'Du har fått en betalfri månad och kan nyttja din kredit igen efter din nästa månadsbetalning {{nextRepaymentDate}}',
        chargePeriodBody:
          'Du har fått en betalfri månad och kan nyttja din kredit igen efter att din pågående dragning lyckats.'
      },
      paymentProviderMigration: {
        title: 'Vi har en ny betalleverantör',
        body: 'Vi har en ny betalleverantör. Uppdatera ditt bankkonto för att fortsätta använda tjänsten.'
      },
      resetPaybackPlan: {
        title: 'Stryk dina skulder och börja om på nytt.',
        body: 'Du har ett unikt erbjudande gällande din förfallna faktura, klicka här för att ta del av erbjudandet.'
      },
      transactionPending: {
        title: 'Transaktion pågår',
        body: 'Vänta någon minut för att göra nya uttag, insättningar och ändra månadsbelopp.'
      },
      transactionDelayed: {
        title: 'Kontakt med banken dröjer',
        body: 'Vi bevakar tjänsten kontinuerligt. Försök igen om 30 minuter.'
      },
      paymentProtectionInsuranceCard: {
        title: 'Du saknar just nu skydd',
        body: 'Öka din trygghet med vårt generösa betalskydd. Försäkringen betalar ditt Fairlo månadsbelopp i upp till 12 mån vid betalningssvårigheter. Läs mer här.'
      },
      paymentProtectionInsuranceCardTwoMonthCampaign: {
        title: 'Vi bjuder på två månader',
        body: 'Du saknar just nu skydd men du kan passa på och teckna vårt generösa betalskydd avgiftsfritt i två månader. Du kan dessutom när som helst säga upp det med ett enkelt knapptryck på Mina Sidor.'
      },
      directDebitMandateInProgress: {
        title: 'Ditt autogiro är snart upplagt',
        body: 'Uppsättning av ditt autogiro pågår. Det kan ta flera bankdagar innan den är aktiverad.'
      }
    },
    features: {
      spinWheel: 'Snurra på hjulet för att välja belopp'
    },
    featureDetails: {
      setupFee: 'Uppläggningsavgift {{amount, currency}}',
      withdrawalFee: 'Uttagsavgift {{amount, currency}}',
      withdrawalFeeZero: 'Uttagsavgift {{amount, currency}} (ord {{regularPrice, currency}})',
      principalLeftLabel: 'Nytt saldo:',
      principalLeftDetails: '{{principalLeft, currency}}',
      principalLeftDetailsWithFees: '{{principalLeft, currency}} inkl. avgifter',
      monthlyRepaymentLabel: 'Nytt månadsbelopp:',
      monthlyRepaymentDetails: '{{monthlyAmount, currency}} i {{period}} månader',
      interestLeftLabel: 'Total ränta {{percentage}}:',
      infoText: 'Du kan enkelt sänka den totala kostnaden genom att höja månadsbeloppet'
    }
  },
  DebtConsolidation: {
    title: 'Fairlo Lösa',
    summaryTitle: 'Lös dyra krediter utan kreditupplysning',
    transfer: {
      disabled: {
        heading: 'Hej <private>{{userName}}</private>,',
        text: 'Vi har valt att tills vidare stänga av funktionen som tillåter dig lösa krediter och lån. Du kan fortfarande ändra ditt månadsbelopp och betala extra.'
      }
    },
    menu: {
      title: 'Vad vill du göra?',
      deposit: 'Återbetala extra',
      monthlyPayment: 'Ändra månadsbelopp',
      additionalDebtTransfer: 'Lösa fler krediter'
    }
  },
  FormErrors: {
    required: 'Vänligen fyll',
    positiveOnly: 'Kan bara bestå av siffor och måste vara större än noll',
    invalidEmail: 'Vänligen fyll i en giltig e-postadress',
    emailTypo: 'Menade du <suggestion>{{suggestion}}</suggestion>?',
    invalidMobile: 'Vänligen fyll i ditt mobilnummer',
    invalidCurrency: 'Måste bestå av siffror',
    maxAmount: 'Vänligen kontrollera värdet',
    invalidNationalNumber: 'Ange personnummer med 10 elle 12 siffror, med eller utan bindestreck.',
    invalidBirthYear: 'Fel år',
    maxLength: 'Vänligen fyll i {{length}} antal bokstäver'
  },
  ApplicationSE: {
    progressBarText: 'Steg {{step}} av {{totalSteps}}',
    errorToastMessage:
      'Din ansökan kunde inte gå vidare till nästa steg. Prova igen. Om problemet kvarstår – kontakta vår kundtjänst.',
    errorToastConflict:
      'Tyvärr, vi kunde inte behandla din ansökan just nu. Ladda om sidan och försök igen om 5 minuter.',
    errorToastUserBlocked:
      'Din ansökan är just nu blockerad. Var snäll och kontakta kundtjänst för ytterligare hjälp.',
    errorToastAlreadyActiveApplication:
      'Du har redan en pågående ansökan som för närvarande behandlas. Om ansökan godkänns kommer du snart att få ett e-postmeddelande med avtal och villkor.',
    intro: {
      title: 'En snäppet schysstare <span>livet-händer-buffert.</span>',
      introText:
        'Ta ut upp till din kreditgräns när som helst och betala endast ränta på det belopp du använder för den tid du använder det.',
      li1: 'Krediter upp till {{amount, currency}}',
      li2: 'Vi bjuder på första uttaget (ord. pris {{amount, currency}})',
      li3: 'Snabba svar, snabba utbetalningar',
      li4: 'Uppläggningsavgift {{amount, currency}}',
      li5: 'Månadsavgift {{amount, currency}}',
      disclaimer: 'Vi gör enbart en kreditupplysning från <b>Dun & Bradstreet</b> (tidigare <b>Bisnode</b>)',
      button: 'Ansök nu'
    },
    personalDetailsForm: {
      title: 'Lite uppgifter om dig',
      emailLabel: 'E-post',
      emailPlaceholder: 'Fyll i din e-postadress',
      mobileLabel: 'Mobilnummer',
      mobilePlaceholder: 'Måste vara ett giltigt mobilnummer',
      nationalNumberLabel: 'Personnummer',
      nationalNumberPlaceholder: 'YYYYMMDDXXXX',
      submitButton: 'Fortsätt'
    },
    employmentStatusForm: {
      title: 'Din anställningstyp',
      optionUnemployed: 'Arbetslös',
      optionStudent: 'Student',
      optionEmployed: 'Fast anställd',
      optionRetired: 'Pensionär/sjukpensionär',
      optionSelfEmployed: 'Egen företagare',
      optionTemporarilyEmployed: 'Vikarie/projektanställd',
      submitButton: 'Fortsätt'
    },
    paymentProtectionInsuranceForm: {
      title: 'Fairlo Betalskydd',
      subTitle: 'Skydda dig mot betalningssvårigheter',
      text1:
        'Försäkringen betalar ditt månadsbelopp i upp till 12 månader. Kostar enbart 0,6 % av utestående skuld.',
      text2: 'Betalskyddet täcker vid:',
      li1: 'Ofrivillig arbetslöshet',
      li2: 'Sjukskrivning',
      li3: 'Sjukhusvistelse',
      li4: 'Dödsfall till följd av olycksfall',
      link: 'Vem kan teckna betalskyddet?',
      radio1: 'Ja tack, jag önskar försäkra mig',
      radio2: 'Nej',
      button: 'Fortsätt'
    },
    financesForm: {
      title: 'Din ekonomi',
      monthlyIncomeLabel: 'Din totala månadsinkomst efter skatt',
      monthlyIncomePlaceholder: 'Måste vara större än noll kr',
      monthlyHouseCostLabel: 'Din boendekostnad per månad',
      monthlyHouseCostPlaceholder: 'Vänligen fyll i din boendekostnad',
      transportationCostLabel: 'Din transportkostnad per månad',
      transportationCostInfo: 'Din kostnad för att utnyttja kollektivtrafik samt diverse fordon',
      otherLoanCostLabel: 'Din månadskostnad för ytterligare lån',
      otherLoanCostPlaceholder: 'Vänligen fyll i dina lånekostnader',
      otherLoanCostInfo: 'Din kostnad för övriga lån samt borgensåtaganden.',
      numberOfChildrenLegend: 'Antal barn under 18 år i hushållet',
      numberOfChildrenOption0: '0',
      numberOfChildrenOption1: '1',
      numberOfChildrenOption2: '2',
      numberOfChildrenOption3: '3+',
      numberOfPersonsLivingLegend: 'Antal vuxna över 18 år i hushållet',
      numberOfPersonsLivingOptionAlone: 'Ensam',
      numberOfPersonsLivingOptionSeveral: 'Flera',
      submitButton: 'Skicka in ansökan'
    },
    applicationPending: {
      title: 'Din ansökan behandlas',
      text: 'Vi är snart klara. Blir du beviljad en kredit kommer du att få ett mail inom 5 minuter med dina avtal. För att göra ett uttag, logga in på Mina sidor här: <loginLink>app.fairlo.se/login</loginLink>. Har du några frågor eller funderingar, tveka inte att kontakta oss på <customerServiceLink>fairlo.se/contact</customerServiceLink>\n\n/Dina vänner på Fairlo'
    }
  },
  DirectPayment: {
    title: 'Betala ditt förfallna belopp med Swish',
    text: 'Beloppet avser följande kostnader:',
    amountToPay: 'Belopp att betala: {{amount, currency}}',
    button: 'Betala med Swish',
    loading: 'Öppna Swish på din mobil och följ instruktionerna',
    Discount: {
      title: 'En fair deal',
      subtitle: 'Betala {{amountToPay, currency}} så stryker vi {{discount, currency}}',
      text: 'Att halka efter kan hända alla så vi vill hjälpa till, vi tycker att du är värd det. Betala av ditt försenade belopp så stryker vi alla förseningsavgifter och dröjsmålsräntor. Efter att du har betalat kan du använda Fairlo som vanligt igen.',
      discount: 'Förseningskostnader',
      initialAmountToPay: 'Förfallet totalbelopp',
      amountToPayAfterDiscount: 'Kvar att betala'
    },
    ResetPaybackPlan: {
      title: 'En ny start på din kredit',
      paragraph1:
        'Allt du behöver göra är att betala {{amountToPay, currency}} så får du en ny start utan förfallna fakturor.',
      paragraph2: 'Dessutom stryker vi inkassoavgifterna och övriga skulder läggs på din kredit.',
      table: {
        offer: 'Erbjudande',
        current: 'Ordinarie',
        amountToPay: 'Att betala nu:',
        installmentAmount: 'Månadsbelopp:',
        outstandingAmount: 'Skuld efter betalning:'
      },
      li1: 'Erbjudandet gäller en gång per kund under en begränsad tid',
      li2: 'Inga uttag möjliga på sex månader',
      li3: 'Erbjudandet kostar {{offerCost, currency}}',
      li4: 'Nästa månadsbelopp dras {{date}}',
      payCurrentButton: 'Betala ordinarie',
      payOfferButton: 'Betala erbjudande'
    },
    QRCode: {
      altTitle: 'Swish QR kod',
      title: 'Scanna det här med mobilen',
      step1: 'Starta Swish-appen i mobilen.',
      step2: 'Tryck på Skanna QR',
      step3: 'Rikta kameran mot QR-koden.'
    }
  },
  FairnessReceipt: {
    heading: 'Fairnesskvitto',
    description:
      'En sammanställning av vad du betalar för och hur mycket vi tjänar så att du kan förstå affären fullt ut och se att villkoren är schyssta.',
    withdrawalAmount: 'Summa nytt uttag',
    newTotalBalance: 'Total summa uttag',
    whatYouPay: 'Vad du betalar',
    interest: 'Ränta för {{months}} månader',
    principal: 'Kapitalbelopp',
    setupFee: 'Uppläggningsavgift',
    withdrawalFee: 'Uttagsavgift',
    totalInstallmentFees: 'Månadsavgift för {{months}} månader',
    whatWePay: 'Vad vi betalar',
    funding: 'Finansiering',
    lossProvision: 'Förlustavsättning',
    servicing: 'Servicing',
    labour: 'Personalkostnader',
    totalAmount: 'Total summa',
    whatWeMake: 'Vad vi tjänar',
    done: 'OK'
  },
  ErrorToast: {
    defaultMessage:
      'Det verkar som att något gick fel. Prova gärna igen – om problemet kvarstår kontakta kundservice@fairlo.se'
  },
  PaymentProtectionInsurance: {
    disclaimer:
      'Bekräftar att jag tagit del av premien, uppfyller kraven för att teckna försäkringen (Vem kan teckna betalskyddet?) samt tagit del av <factSheetLink>produktfaktablad</factSheetLink>, <purchaseInfoLink>för- & efterköpsinformation</purchaseInfoLink>, <mediationInfoLink>information om försäkringsförmedling</mediationInfoLink>, godkänner de <fullTermsLink>fullständiga villkor</fullTermsLink> för betalskyddet och accepterar att få försäkringsinformationen digitalt.',
    insurancePage: {
      title: 'Fairlo Betalskydd',
      description:
        'Fairlo betalskydd betalar dina Fairlo månadskostnader upp till 12 månader om du råkar ut för:',
      li1: 'Ofrivillig arbetslöshet',
      li2: 'Sjukskrivning',
      li3: 'Sjukhusvistelse',
      li4: 'Dödsfall till följd av olycksfall',
      cost: 'Fairlo betalskydd kostar 0,6 % av din utomstående skuld per månad (max 350 kr, minst 30 kr).',
      costExampleTitle: 'Prisexempel',
      costExample: 'För en skuld på 10 000 kr blir kostnaden 60 kr',
      prerequisites: 'Förutsättningar',
      button: 'Godkänn',
      acceptTermsTitle: 'Jag bekräftar att jag tagit del av',
      acceptTerms:
        'premien, uppfyller förutsättningarna (behovsantaganden & teckningskraven) för betalskyddet, samt tagit del av <factSheetLink>produktfaktablad</factSheetLink>, <purchaseInfoLink>för- & efterköpsinformation</purchaseInfoLink>, <mediationInfoLink>information om försäkringsförmedling</mediationInfoLink>, godkänner de <fullTermsLink>fullständiga villkoren för betalskyddet</fullTermsLink> och accepterar att få försäkringsinformationen digitalt.',
      twoMonthCampaignParagraph:
        'Passa på och teckna vår supergenerösa försäkring nu så bjuder vi på avgiften de första två månaderna. Tro oss, det är en bra deal. Och om du ändrar dig så går det blixtsnabbt att ta bort den på Mina Sidor, när du vill.'
    },
    tokenExpired: {
      title: 'Oj då, något gick fel',
      body: 'Länken är vara inaktiverad av någon av följande anledningar:',
      reason1: 'Du nyligen avslutat din försäkring och behöver vänta en månad innan du kan signera igen.',
      reason2: 'Du är inte längre aktiv kund hos Fairlo',
      reason3: 'Länken är utdaterad och inte längre aktiv',
      contact:
        'Har du frågor är du välkommen att kontakta får kundtjänst på <contactLink>www.fairlo.se/contact</contactLink>.'
    },
    insuranceAlreadyActive: {
      title: 'Du har redan en aktiv försäkring',
      body: 'Visst är det bra med försäkringar men man får tyvärr bara ha en per person.',
      contact:
        'Har du frågor eller funderingar är du välkommen att kontakta vår kundtjänst på <contactLink>www.fairo.se/contact</contactLink>'
    }
  },
  BankId: {
    startBankIdApp: 'Starta BankID appen',
    openBankIdApp: 'Öppna BankID appen',
    openBankIdInAnotherDevice: 'BankID på annan enhet',
    scanQRcode: 'Scanna QR Koden',
    verificationFailed: 'Verifiering med BankID misslyckades',
    retry: 'Försök med BankID igen',
    hintCodeMessages: {
      RFA1: 'Starta BankID-appen.',
      RFA9: 'Skriv in din säkerhetskod i BankID-appen och välj Identifiera eller Skriv under.',
      RFA13: 'Försöker starta BankID-appen.',
      RFA14A:
        'Söker efter BankID, det kan ta en liten stund… Om det har gått några sekunder och inget BankID har hittats har du sannolikt inget BankID som går att använda för den aktuella identifieringen/underskriften i den här enheten. Om du inte har något BankID kan du skaffa ett hos din bank. Om du har ett BankID på en annan enhet kan du starta din BankID-app där.',
      RFA14B:
        'Söker efter BankID, det kan ta en liten stund… Om det har gått några sekunder och inget BankID har hittats har du sannolikt inget BankID som går att använda för den aktuella identifieringen/underskriften i den här datorn. Om du har ett BankID-kort, sätt in det i kortläsaren. Om du inte har något BankID kan du skaffa ett hos din bank.',
      RFA21: 'Identifiering eller underskrift pågår.',
      RFA23: 'Fotografera och läs av din ID-handling med BankID-appen.',
      RFA8: 'BankID-appen svarar inte. Kontrollera att den är startad och att du har internetanslutning. Om du inte har något giltigt BankID kan du skaffa ett hos din bank. Försök sedan igen.',
      RFA16:
        'Det BankID du försöker använda är för gammalt eller spärrat. Använd ett annat BankID eller skaffa ett nytt hos din bank.',
      RFA6: 'Åtgärden avbruten.',
      RFA3: 'Åtgärden avbruten. Försök igen',
      RFA17A:
        'BankID-appen verkar inte finnas i din dator eller mobil. Installera den och skaffa ett BankID hos din bank. Installera appen från din appbutik eller <a href="https://install.bankid.com" target="_blank" rel="noreferrer">https://install.bankid.com</a>',
      RFA17B:
        'Misslyckades att läsa av QR-koden. Starta BankID-appen och läs av QR-koden. Kontrollera att BankID-appen är uppdaterad. Om du inte har BankID-appen måste du installera den och skaffa ett BankID hos din bank. Installera appen från din appbutik eller <a href="https://install.bankid.com" target="_blank" rel="noreferrer">https://install.bankid.com</a>.',
      RFA22: 'Okänt fel. Försök igen.'
    },
    errorMessages: {
      RFA4: 'En identifiering eller underskrift pågår redan.',
      RFA22: 'Okänt fel. Försök igen.',
      RFA5: 'Internt tekniskt fel. Försök igen'
    }
  },
  BrokerApplication: {
    approved: {
      title: 'Grattis!\nDu är preliminärt beviljad {{creditLimit, currency}}',
      ppiHeading: 'Skydda dig mot betalningssvårigheter',
      ppiText:
        'Försäkringen betalar ditt månadsbelopp i upp till 12 månader. Kostar enbart 0,6 % av utestående skuld.\n\n<b>Betalskyddet täcker vid:</b>\nOfrivillig arbetslöshet, Sjukskrivning, Sjukhusvistelse och dödsfall till följd av olycksfall.',
      ppiButton: 'Vem kan teckna betalskyddet?',
      ppiRadioYes: 'Ja tack, jag önskar försäkra mig',
      ppiRadioNo: 'Nej',
      submitButton: 'Signera avtal'
    },
    expired: {
      title: 'Erbjudandet har förfallit',
      text: 'Tyvärr har giltighetstiden för detta erbjudande löpt ut. Om du vill kan du ansöka på nytt genom att klicka på knappen nedan.',
      newApplication: 'Gör en ny ansökan',
      backToSite: 'Gå tillbaka till Fairlo.se'
    }
  },
  Transactions: {
    title: 'Betalplan & historik',
    planTab: 'Betalplan',
    historyTab: 'Historik',
    noHistory: 'Det finns ingen historik',
    noPlanNewUser: 'Du har ingen nyttjad kredit',
    noPlan: 'Du har inga kommande betalningar',
    detailsTitle: 'Händelse',
    transaction: {
      type: {
        DEPOSIT: 'Insättning',
        WITHDRAW: 'Uttag',
        PAYBACK: 'Månadsbelopp',
        REFUND: 'Återbetalning',
        SETUP_FEE: 'Uppsättningsavgift'
      },
      product: {
        CREDIT: 'Varda',
        DEBT_CONSOLIDATION: 'Lösa',
        PAYMENT_PROTECTION_INSURANCE: 'Betalskydd'
      },
      status: {
        INCOMPLETE: 'Kommande',
        COMPLETE: 'Betald',
        COMPLETE_DEPOSIT: 'Mottagen',
        COMPLETE_WITHDRAW: 'Utbetald',
        CHARGE: 'Pågående',
        IN_EXTENDED_PAYMENT_PERIOD: 'Utökad betalperiod',
        OVERDUE: 'Förfallen',
        PAYMENT_FREE_MONTH: 'Betalfri månad'
      },
      detailType: {
        PRINCIPAL: 'Kapital',
        INTEREST: 'Ränta',
        OVERDUE_INTEREST: 'Dröjsmålsränta',
        WITHDRAWAL_FEE: 'Uttagsavgift',
        SETUP_FEE: 'Uppsättningsavgift',
        INSTALLMENT_FEE: 'Månadsavgift',
        OVERDUE_FEE: 'Påminnelse',
        DEBT_COLLECTION_FEE: 'Inkassoavgift',
        DIRECT_DEBIT_MISSING_FEE: 'Saknat Autogiro',
        REMIT: 'Efterskänkes',
        UNKNOWN: 'Okänd',
        TERMINATION: 'Uppsägning',
        CAMPAIGN_DISCOUNT: 'Avdragen rabatt',
        PREMIUM: 'Betalskydd',
        COMPOUNDED_PRINCIPAL: 'Obetalda räntor och avgifter'
      },
      feeRemoval: '{{feeType}} åter',
      dueDate: 'Förfallodatum',
      completionDate: 'Betaldatum',
      statusTitle: 'Status',
      totalAmount: 'Total belopp'
    }
  },
  Maintenance: {
    title: 'Ursäkta röran!',
    text1: 'Fairlos hemsida är nere för underhåll.',
    text2: 'Vi uppdaterar vår databas och är tillbaka inom kort.'
  },
  MoneezyBanner: {
    title: 'Vi rekommenderar att du testar hos vår partner Moneezy.',
    button: 'Ja, ta mig till Moneezy',
    disclaimer:
      'Fairlo får kommission för eventuella affärer som du gör med Moneezy. Vi delar ingen data om dig.'
  },
  CreditTierSummary: {
    title: 'Avgifter, räntor och återbetalningar',
    creditLimit: 'Låneutrymme',
    interestRate: 'Ränta',
    installmentAmount: 'Månadsbelopp',
    fees: {
      title: 'Avgifter',
      setupFee: 'Uppläggningsavgift',
      withdrawalFee: 'Uttagsavgift',
      installmentFee: 'Månadsavgift',
      directDebitMissingFee: 'Utan autogiro',
      upTo: 'upp till {{amount, currency}}',
      upToPerMonth: 'upp till {{amount, currency}}/mån',
      perMonth: '{{amount, currency}}/mån'
    }
  }
};

export default swedish;
