import { AnchorButton, Heading, Stack, Text, device } from '@fairlo/ui-kit';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';
import styled from 'styled-components';
import ErrorImage from '../../../../src/assets/images/error.svg';
import { routeMap } from '../../../routes/routeMap';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 24px;

  @media (${device.tablet}) {
    flex-direction: row;
    gap: 48px;
    padding-top: 80px;
  }
`;

const Image = styled.img`
  max-width: 552px;
`;

const Footer = styled.div`
  margin-top: auto;

  @media (${device.tablet}) {
    margin-top: 0;
  }
`;

type Props = {
  browserNavigationEnabled?: boolean;
};

function ErrorInfo({ browserNavigationEnabled = false }: Props) {
  const { t } = useTranslation('ErrorInfo');

  return (
    <>
      <StyledContainer>
        <Stack>
          <Heading as="h1" size="m">
            {t('title')}
          </Heading>
          <Text size="l">{t('text')}</Text>
        </Stack>
        <Image src={ErrorImage} alt="Error" />
      </StyledContainer>
      <Footer>
        {/* When the ErrorBoundary triggers, react router navigation is disabled. To fix that, we
        need to navigate through the browser */}
        {browserNavigationEnabled ? (
          <AnchorButton href={routeMap.home}>{t('button')}</AnchorButton>
        ) : (
          <AnchorButton as={Link} to={routeMap.home}>
            {t('button')}
          </AnchorButton>
        )}
      </Footer>
    </>
  );
}

export default ErrorInfo;
