import { AnchorButton, List, ListItem, ListItemText, Stack, Text } from '@fairlo/ui-kit';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router';
import { DepositType } from '../../../../api';
import { QueryParam } from '../../../../constants';
import usePaymentQuery from '../../../../pages/Products/UK/views/Credit/views/Deposit/hooks/usePaymentQuery';
import { routeMap } from '../../../../routes/routeMap';
import { OverdueInfoResponse } from '../../../../types/credit';
import { constructURL } from '../../../../utils/url';
import { FullWidthContainer } from '../../../styled';
import Modal from '../../Modal';
import { ModalParagraph } from '../shared';
import { ModalProps } from '../types';

type Payload = { overdueInfo: OverdueInfoResponse };
type Props = ModalProps<Payload>;

const OverduePayment = ({ payload }: Props) => {
  const { t } = useTranslation(['Modal', 'Common']);
  const {
    overdueInfo: { overdue, accruedInterest, totalOverdue }
  } = payload;

  // Used to prefetch the payment data needed for the deposit summary
  usePaymentQuery();

  return (
    <Modal title={t('OverduePayment.title')}>
      <Stack>
        <ModalParagraph>{t('OverduePayment.info')}</ModalParagraph>
        <FullWidthContainer>
          <List>
            <ListItem>
              <ListItemText text={t('OverduePayment.infoItemTitle1')} />
              <ListItemText text={t('Common:money', overdue)} />
            </ListItem>
            <ListItem>
              <ListItemText text={t('OverduePayment.infoItemTitle2')} />
              <ListItemText text={t('Common:money', accruedInterest)} />
            </ListItem>
            <ListItem>
              <Text as="span" weight="bold">
                {t('OverduePayment.infoItemTitle3')}
              </Text>
              <Text as="span" weight="bold">
                {t('Common:money', totalOverdue)}
              </Text>
            </ListItem>
          </List>
        </FullWidthContainer>
      </Stack>
      <Modal.Actions>
        <AnchorButton
          as={Link}
          color="primary"
          to={constructURL(routeMap.credit.deposit.UK.summary, {
            relative: true,
            queryParams: {
              [QueryParam.DEPOSIT_AMOUNT]: `${totalOverdue.amount}`,
              [QueryParam.DEPOSIT_TYPE]: DepositType.OVERDUE
            }
          })}
        >
          {t('OverduePayment.button', totalOverdue)}
        </AnchorButton>
      </Modal.Actions>
    </Modal>
  );
};

export default OverduePayment;
